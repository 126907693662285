define("share-drop/templates/components/popover-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jdiHzKrH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"popover\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"popover-body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"popover-icon\"],[9],[0,\"\\n      \"],[7,\"i\"],[12,\"class\",[21,\"iconClass\"]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"p\"],[9],[14,1],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"popover-buttons\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"cancelButtonLabel\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"type\",\"button\"],[9],[1,[21,\"cancelButtonLabel\"],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"confirmButtonLabel\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"type\",\"button\"],[9],[1,[21,\"confirmButtonLabel\"],false],[3,\"action\",[[22,0,[]],\"confirm\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/components/popover-confirm.hbs"
    }
  });

  _exports.default = _default;
});