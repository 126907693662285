define("share-drop/templates/errors/filesystem-unavailable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEA0erus",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Uh oh. Looks like there's some issue and we won't be able\"],[7,\"br\"],[9],[10],[0,\"to save your files.\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"If you've opened this app in incognito/private window,\"],[7,\"br\"],[9],[10],[0,\"try again in a normal one.\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/errors/filesystem-unavailable.hbs"
    }
  });

  _exports.default = _default;
});