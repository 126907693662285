define("share-drop/components/user-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['peer'],
    classNameBindings: ['peer.peer.state']
  });

  _exports.default = _default;
});