define("share-drop/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cdb4RRwI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"modal-overlay\"],[9],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/components/modal-dialog.hbs"
    }
  });

  _exports.default = _default;
});