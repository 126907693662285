define("share-drop/components/room-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    classNames: ['room-url'],

    didInsertElement() {
      this.$().focus().select();
    },

    copyValueToClipboard() {
      if (window.ClipboardEvent) {
        const pasteEvent = new window.ClipboardEvent('paste', {
          dataType: 'text/plain',
          data: this.$().val()
        });
        document.dispatchEvent(pasteEvent);
      }
    }

  });

  _exports.default = _default;
});