define("share-drop/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EJyCV0Uq",
    "block": "{\"symbols\":[\"peer\"],\"statements\":[[7,\"main\"],[11,\"class\",\"l-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"user others\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"peer-widget\",null,[[\"peer\",\"hasCustomRoomName\",\"webrtc\"],[[22,1,[]],[23,[\"hasCustomRoomName\"]],[23,[\"webrtc\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"you\",\"uuid\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"user you\"],[9],[0,\"\\n      \"],[1,[27,\"user-widget\",null,[[\"user\"],[[23,[\"you\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"circles\"],[11,\"viewBox\",\"-0.5 -0.5 1140 700\"],[9],[0,\"\\n    \"],[7,\"circle\"],[11,\"class\",\"circle\"],[11,\"cx\",\"570\"],[11,\"cy\",\"570\"],[11,\"r\",\"30\"],[11,\"stroke\",\"rgba(160,160,160, 1)\"],[9],[10],[0,\"\\n    \"],[7,\"circle\"],[11,\"class\",\"circle\"],[11,\"cx\",\"570\"],[11,\"cy\",\"570\"],[11,\"r\",\"100\"],[11,\"stroke\",\"rgba(160,160,160,.9)\"],[9],[10],[0,\"\\n    \"],[7,\"circle\"],[11,\"class\",\"circle\"],[11,\"cx\",\"570\"],[11,\"cy\",\"570\"],[11,\"r\",\"200\"],[11,\"stroke\",\"rgba(160,160,160,.8)\"],[9],[10],[0,\"\\n    \"],[7,\"circle\"],[11,\"class\",\"circle\"],[11,\"cx\",\"570\"],[11,\"cy\",\"570\"],[11,\"r\",\"300\"],[11,\"stroke\",\"rgba(160,160,160,.7)\"],[9],[10],[0,\"\\n    \"],[7,\"circle\"],[11,\"class\",\"circle\"],[11,\"cx\",\"570\"],[11,\"cy\",\"570\"],[11,\"r\",\"400\"],[11,\"stroke\",\"rgba(160,160,160,.6)\"],[9],[10],[0,\"\\n    \"],[7,\"circle\"],[11,\"class\",\"circle\"],[11,\"cx\",\"570\"],[11,\"cy\",\"570\"],[11,\"r\",\"500\"],[11,\"stroke\",\"rgba(160,160,160,.5)\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/index.hbs"
    }
  });

  _exports.default = _default;
});