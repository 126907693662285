define("share-drop/templates/components/user-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1qokVf6R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"avatar\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"gravatar\"],[12,\"src\",[23,[\"user\",\"avatarUrl\"]]],[12,\"alt\",[23,[\"users\",\"label\"]]],[12,\"title\",[28,[\"peer id: \",[23,[\"user\",\"uuid\"]]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"user-info\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"user-label\"],[9],[0,\"You\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"user-ip\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[1,[23,[\"user\",\"label\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/components/user-widget.hbs"
    }
  });

  _exports.default = _default;
});