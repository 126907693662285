define("share-drop/templates/about-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4lQRtMdU",
    "block": "{\"symbols\":[],\"statements\":[[0,\"ShareDrop lets you share files with others.\\nOther people will see you as \"],[7,\"b\"],[9],[1,[23,[\"you\",\"label\"]],false],[10],[0,\".\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/about-you.hbs"
    }
  });

  _exports.default = _default;
});