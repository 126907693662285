define("share-drop/components/peer-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['peer'],
    classNameBindings: ['peer.peer.state'],
    peer: null,
    hasCustomRoomName: false,
    webrtc: null,
    // TODO inject webrtc as a service
    label: Ember.computed.alias('peer.label'),
    isIdle: Ember.computed.equal('peer.state', 'idle'),
    hasSelectedFile: Ember.computed.equal('peer.state', 'has_selected_file'),
    isSendingFileInfo: Ember.computed.equal('peer.state', 'sending_file_info'),
    isAwaitingFileInfo: Ember.computed.equal('peer.state', 'awaiting_file_info'),
    isAwaitingResponse: Ember.computed.equal('peer.state', 'awaiting_response'),
    hasReceivedFileInfo: Ember.computed.equal('peer.state', 'received_file_info'),
    hasDeclinedFileTransfer: Ember.computed.equal('peer.state', 'declined_file_transfer'),
    hasError: Ember.computed.equal('peer.state', 'error'),
    filename: Ember.computed('peer.transfer.{file,info}', function () {
      const file = this.get('peer.transfer.file');
      const info = this.get('peer.transfer.info');

      if (file) {
        return file.name;
      }

      if (info) {
        return info.name;
      }

      return null;
    }),
    actions: {
      // TODO: rename to something more meaningful (e.g. askIfWantToSendFile)
      uploadFile(data) {
        const peer = this.get('peer');
        const {
          file
        } = data; // Cache the file, so that it's available
        // when the response from the recipient comes in

        peer.set('transfer.file', file);
        peer.set('state', 'has_selected_file');
      },

      sendFileTransferInquiry() {
        const webrtc = this.get('webrtc');
        const peer = this.get('peer');
        webrtc.connect(peer.get('peer.id'));
        peer.set('state', 'establishing_connection');
      },

      cancelFileTransfer() {
        this._cancelFileTransfer();
      },

      abortFileTransfer() {
        this._cancelFileTransfer();

        const webrtc = this.get('webrtc');
        const connection = this.get('peer.peer.connection');
        webrtc.sendCancelRequest(connection);
      },

      acceptFileTransfer() {
        const peer = this.get('peer');

        this._sendFileTransferResponse(true);

        peer.get('peer.connection').on('receiving_progress', progress => {
          peer.set('transfer.receivingProgress', progress);
        });
        peer.set('state', 'sending_file_data');
      },

      rejectFileTransfer() {
        const peer = this.get('peer');

        this._sendFileTransferResponse(false);

        peer.set('transfer.info', null);
        peer.set('state', 'idle');
      }

    },

    _cancelFileTransfer() {
      const peer = this.get('peer');
      peer.setProperties({
        'transfer.file': null,
        state: 'idle'
      });
    },

    _sendFileTransferResponse(response) {
      const webrtc = this.get('webrtc');
      const peer = this.get('peer');
      const connection = peer.get('peer.connection');
      webrtc.sendFileResponse(connection, response);
    },

    errorTemplateName: Ember.computed('peer.errorCode', function () {
      const errorCode = this.get('peer.errorCode');
      return errorCode ? `errors/popovers/${errorCode}` : null;
    })
  });

  _exports.default = _default;
});