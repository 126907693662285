define("share-drop/templates/errors/popovers/multiple-files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KUGfB6mR",
    "block": "{\"symbols\":[],\"statements\":[[0,\"You can only send one file at a time.\\n\"],[7,\"br\"],[9],[10],[7,\"br\"],[9],[10],[0,\"\\nTIP: You can archive mulitple files and send as one.\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/errors/popovers/multiple-files.hbs"
    }
  });

  _exports.default = _default;
});