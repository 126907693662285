define("share-drop/models/user", ["exports", "share-drop/models/peer"], function (_exports, _peer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const User = _peer.default.extend({
    serialize() {
      const data = {
        uuid: this.get('uuid'),
        public_ip: this.get('public_ip'),
        label: this.get('label'),
        avatarUrl: this.get('avatarUrl'),
        peer: {
          id: this.get('peer.id')
        }
      };
      return data;
    }

  });

  var _default = User;
  _exports.default = _default;
});