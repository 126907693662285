define("share-drop/templates/about-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Shg6TiVR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\"],[[27,\"action\",[[22,0,[]],\"closeModal\"],[[\"target\"],[[23,[\"currentRoute\"]]]]]]],{\"statements\":[[0,\"  \"],[7,\"h2\"],[11,\"class\",\"logo\"],[9],[7,\"span\"],[9],[0,\"ShareDrop\"],[10],[10],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Share files between devices in different networks\"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n    Copy provided address and send it to the other person...\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[27,\"room-url\",null,[[\"value\",\"readonly\",\"style\"],[[23,[\"currentUrl\"]],\"readonly\",\"display: block; margin: auto;\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n    Or you can scan it on the other device.\\n  \"],[10],[0,\"\\n\\n\\n  \"],[7,\"p\"],[11,\"class\",\"qr-code\"],[9],[0,\"\\n    \"],[1,[27,\"qr-code\",null,[[\"text\"],[[23,[\"currentUrl\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n    Once the other person open this page in a browser, you'll see each other's avatars.\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[9],[0,\"\\n    Drag and drop a file directly on other person's avatar or click the avatar and select the file you want to send. The file transfer will start once the recipient accepts the file.\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n    \"],[7,\"button\"],[9],[0,\"Got it!\"],[3,\"action\",[[22,0,[]],\"closeModal\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/about-room.hbs"
    }
  });

  _exports.default = _default;
});