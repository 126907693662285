define("share-drop/services/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    hasAnalytics() {
      return window.ga && typeof window.ga === 'function';
    },

    trackEvent(category, action, label, value) {
      if (this.hasAnalytics()) {
        window.ga('send', 'event', category, action, label, value);
      }
    }

  };
  _exports.default = _default;
});