define("share-drop/templates/errors/browser-unsupported", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F99S1Lb7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"We're really sorry, but your browser is not supported.\"],[7,\"br\"],[9],[10],[0,\"Please use the latest desktop or Android version of\"],[7,\"br\"],[9],[10],[7,\"b\"],[9],[0,\"Chrome\"],[10],[0,\", \"],[7,\"b\"],[9],[0,\"Opera\"],[10],[0,\" or \"],[7,\"b\"],[9],[0,\"Firefox\"],[10],[0,\".\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "share-drop/templates/errors/browser-unsupported.hbs"
    }
  });

  _exports.default = _default;
});