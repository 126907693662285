define("share-drop/router", ["exports", "share-drop/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  }); // eslint-disable-next-line array-callback-return

  Router.map(function () {
    this.route('room', {
      path: '/rooms/:room_id'
    });
  });
  var _default = Router;
  _exports.default = _default;
});