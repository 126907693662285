define("share-drop/controllers/application", ["exports", "uuid", "share-drop/models/user"], function (_exports, _uuid, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    avatarService: Ember.inject.service('avatar'),

    init(...args) {
      this._super(args);

      const id = window.ShareDrop.userId;
      const ip = window.ShareDrop.publicIp;
      const avatar = this.avatarService.get();

      const you = _user.default.create({
        uuid: id,
        public_ip: ip,
        avatarUrl: avatar.url,
        label: avatar.label
      });

      you.set('peer.id', id);
      this.set('you', you);
    },

    actions: {
      redirect() {
        const uuid = (0, _uuid.default)();
        const key = `show-instructions-for-room-${uuid}`;
        sessionStorage.setItem(key, 'yup');
        this.transitionToRoute('room', uuid);
      }

    }
  });

  _exports.default = _default;
});